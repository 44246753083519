import React, { Fragment, useRef, useState } from 'react'
import { Button, Card, Col, Form, FormGroup, Row, Spinner } from 'react-bootstrap'
import TermsPrivacyy from '../../../services/termsprivacyy.service';
import { useEffect } from 'react';
import { Divider } from 'antd';
import { enqueueSnackbar } from 'notistack';
import withLoader from '../../../layout/loader/withLoader';

const TermsPrivacy = () => {
  const [termsOpen, setTermsOpen] = useState(false);
  const [isTermsErr, setIsTermsErr] = useState(false);
  const [isPrivacyErr, setIsPrivacyErr] = useState(false);
  const [privacyOpen, setPrivacyOpen] = useState(false);
  const [loaderPrivacy, setLoaderPrivacy] = useState(false);
  const [loaderTerms, setLoaderTerms] = useState(false);
  const [termsUrl, setTermsUrl] = useState('');
  const [privacyUrl, setPrivacyUrl] = useState('');
  const [id, setId] = useState('');
  const inputTermRef = useRef()
  const inputPrivacyRef = useRef();

  const [showCancellation, setShowCancellation] = useState({
    cancellations: [
      {
        hours: 72,
        percentageCredited: 0
      },
      {
        hours: 48,
        percentageCredited: 0
      },
      {
        hours: 24,
        percentageCredited: 0
      }
    ],
    bookingFee: 0,
    policyText: "string"
  })

  const handleUpdateTerms = async () => {
    setTermsOpen(!termsOpen)
    if (!termsOpen) {
      setTimeout(() => {
        inputTermRef.current.focus();
      }, 0)
    } else {
      setLoaderTerms(true)
      const response = await TermsPrivacyy.updateTermsCondition(showCancellation)
      if (termsUrl === "") {
        setIsTermsErr(true);
        return
      }
      getUrls()
      setLoaderTerms(false)
      enqueueSnackbar(response.data.message, {
        variant: "success", anchorOrigin: { vertical: "bottom", horizontal: "right" }
      })
    }
  }
  const handleChangeTerms = (e) => {
    const input = e.target.value
    setShowCancellation({ ...showCancellation, policyText: input })
    if (input === "") {
      setIsTermsErr(true)
    } else {
      setIsTermsErr(false)
    }
  }

  const ChangePolicyData = (value, index) => {
    if (index === "book") {
      setShowCancellation({ ...showCancellation, bookingFee: value })
    } else {
      setShowCancellation((prevShowCancellation) => {
        const updatedCancellations = [...prevShowCancellation.cancellations];
        updatedCancellations[index] = { ...updatedCancellations[index], percentageCredited: value };
        return { ...prevShowCancellation, cancellations: updatedCancellations };
      });
    }
  }

  const getUrls = async () => {
    const response = await TermsPrivacyy.getUrl()
    const result = response?.data?.data;
    setTermsUrl(result);
    setShowCancellation(result);
    setId(result?.id);
  }
  useEffect(() => {
    getUrls()
  }, [])

  return (
    <>
      <Fragment>
        <Row className="row-sm mt-5 page-header">
          <Col xs={12} md={6} lg={6} xl={6}>
            <Card className="custom-card transcation-crypto"
              style={{ fullwidth: "100%", marginTop: "0px" }}>
              <Card.Body>
                <Row>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <h2
                      className="main-content-title tx-24 mb-0"
                      style={{ marginBottom: "40px" }}
                    >
                      Cancellation Policy
                    </h2>
                    <Button
                      variant="primary"
                      type="button"
                      className="my-2 btn-icon-text"
                      onClick={() => {
                        handleUpdateTerms();
                      }}
                      disabled={isTermsErr}
                    >
                      {loaderTerms ? <Spinner /> : (!termsOpen ? (<><i className="fe fe-edit"></i> Update</>) : 'Submit')}
                    </Button>
                  </div>
                  <FormGroup className="form-group" controlId="validationFormik101">
                    <textarea className='w-100 mb-4' style={{ minHeight: '100px' }} value={showCancellation.policyText} onChange={(e) => handleChangeTerms(e)} disabled={!termsOpen ? true : false} ref={inputTermRef} />

                    <h6 className='fs-5'>Credit</h6>
                    <div className='row m-0 p-0'>
                      <div className='d-flex mb-3 col-6' style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px", justifyContent: "start"
                      }}>
                        <Form.Label
                          style={{
                            textAlign: "start",
                            color: "#000",
                          }}
                        >
                          72 hour
                        </Form.Label>
                        <Form.Control
                          type="number"
                          rows={2}
                          value={showCancellation.cancellations[0].percentageCredited}
                          disabled={!termsOpen ? true : false}
                          onChange={(e) => ChangePolicyData(e.target.value, 0)}
                          placeholder="72 hr credit"
                          style={{
                            maxWidth: "320px",
                            width: "100%"
                          }}
                        />
                        <span>%</span>
                      </div>
                      <div className='d-flex mb-3 col-6' style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px", justifyContent: "start"
                      }}>
                        <Form.Label
                          style={{
                            textAlign: "start",
                            color: "#000",
                          }}
                        >
                          48 hour
                        </Form.Label>
                        <Form.Control
                          type="number"
                          rows={2}
                          value={showCancellation.cancellations[1].percentageCredited}
                          disabled={!termsOpen ? true : false}
                          onChange={(e) => ChangePolicyData(e.target.value, 1)}
                          placeholder="48 hr credit"
                          style={{
                            maxWidth: "320px",
                            width: "100%"
                          }}
                        />
                        <span>%</span>
                      </div>
                      <div className='d-flex mb-3 col-6' style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px", justifyContent: "start"
                      }}>
                        <Form.Label
                          style={{
                            textAlign: "start",
                            color: "#000",
                          }}
                        >
                          24 hour
                        </Form.Label>
                        <Form.Control
                          type="number"
                          rows={2}
                          value={showCancellation.cancellations[2].percentageCredited}
                          onChange={(e) => ChangePolicyData(e.target.value, 2)}
                          disabled={!termsOpen ? true : false}
                          placeholder="24 hr credit"
                          style={{
                            maxWidth: "320px",
                            width: "100%"
                          }}
                        />
                        <span>%</span>
                      </div>

                    </div>
                    <div className='mt-4'>
                      <h6 className='fs-6'>Booking Fees</h6>
                      <div className='d-flex mb-3 col-6' style={{
                        whiteSpace: "nowrap",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px", justifyContent: "start"
                      }}>
                        <Form.Label
                          style={{
                            textAlign: "start",
                            color: "#000",
                          }}
                        >
                          Fees
                        </Form.Label>
                        <Form.Control
                          type="number"
                          rows={2}
                          value={showCancellation.bookingFee}
                          disabled={!termsOpen ? true : false}
                          onChange={(e) => ChangePolicyData(e.target.value, "book")}
                          placeholder="Fees"
                          style={{
                            maxWidth: "320px",
                            width: "100%",
                          }}
                        />
                        <span>%</span>
                      </div>
                    </div>
                  </FormGroup>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>
    </>
  )
}

export default withLoader(TermsPrivacy)
