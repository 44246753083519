import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "./app/authentication/login";
import MatxLayout from "./app/component/maxLayout";
import NotFound from "./app/layout/sessions/NotFound";
import Faqs from "./app/component/admin/faqs/Faqs";
import TermsPrivacy from "./app/component/admin/termsprivacy/TermsPrivacy";

function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}
const Email = localStorage.getItem("email");

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        index: true,
        element: (
          <>
            <RedirectionWrapper to="/faqs" />
          </>
        ),
      },
      {
        path: "/faqs",
        element: <Faqs />,
      },
      {
        path: "/policy",
        element: <TermsPrivacy />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <>
        <RedirectionWrapper to="/faqs" />
      </>
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
