import serverCall from "../../serverCall";
const getUrl = async () => {
    const response = await serverCall.get('/policy/get-policy');
    return response
}
const updatePrivacyPolicy = async (data) => {
    const response = await serverCall.post(`document-urls/update-privacyPolicyUrl`, data);
    return response
}
const updateTermsCondition = async (data) => {
    const response = await serverCall.post(`/policy/update-policy`, data);
    return response
}
const TermsPrivacyy = {
    getUrl,
    updatePrivacyPolicy,
    updateTermsCondition
};
export default TermsPrivacyy;