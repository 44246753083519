import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import { enqueueSnackbar } from 'notistack';

const DeleteModal = (props) => {
    const { propertyName, ProprtyDelete, FaqDelete, name, mixdata, deleteFaq, deleteProperty } = props
    const handleDelete = (e) => {
        e.preventDefault();
        if (mixdata === "property") {
            ProprtyDelete()
        } else {
            FaqDelete()
        }
    }
    return (
        <>
            <Modal
                {...props}
                size="large"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton >
                    <Modal.Title>Delete Property
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <i className="icon ion-ios-checkmark-circle-outline tx-100 tx-danger  mg-t-20 "></i>

                    <h4 className="tx-danger tx-semibold mg-b-20">Delete?</h4>
                    <p className="mg-b-20 mg-x-20 ">
                        Are you sure want to delete <b>
                            {mixdata === "property" ? propertyName : name}
                        </b> ?
                    </p>
                    <Button
                        type="button"
                        // onClick={(e) => {
                        //     e.preventDefault();
                        //     ProprtyDelete();
                        //     FaqDelete();
                        // }}
                        onClick={(e) => { handleDelete(e) }}
                        variant="danger"
                    >
                        {(deleteFaq || deleteProperty) ? <Spinner /> : 'Delete'}
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DeleteModal
