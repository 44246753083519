import { get } from "lodash";
import serverCall from "../../serverCall";

const signUp = async (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  const response = await serverCall.post(`/auth/admin/login?email=${email}&password=${password}`)
  if (response.data) {
    localStorage.setItem('token', response.data.token);
    return response.data;
  }
};
const AuthenticationService = {
  signUp
};

export default AuthenticationService;
