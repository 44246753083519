let MENUITEMS = [];
MENUITEMS = [
    {
        Items: [
            {
                path: `/faqs`,
                icon: "ti-pencil-alt",
                type: "link",
                active: false,
                selected: false,
                title: "FAQs",
            },
            {
                path: `/policy`,
                icon: "mdi mdi-book-multiple",
                type: "link",
                active: false,
                selected: false,
                title: "Cancellation Privacy",
            },
        ],
    }
]

export { MENUITEMS };
