import serverCall from "../../serverCall";

const AllFaqs = async ({ pageNumber, pageSize }) => {
    const response = await serverCall.get(`faq/getall?page=${pageNumber}&limit=${pageSize}`);
    return response;
};
const CreateFaq = async (question) => {
    const response = await serverCall.post(`/faq/admin/create`, question)
    return response
}
const UpdateFaq = async (id, question, answer) => {
    const response = await serverCall.put(`faq/admin/update/${id}`, { question, answer })
    return response
}
const GetOne = async (id) => {
    const response = await serverCall.get(`/faq/${id}`)
    return response
}
const DeleteOne = async (id) => {
    const response = await serverCall.delete(`/faq/admin/delete/${id}`)
    return response
}
const FaqsService = {
    AllFaqs, CreateFaq, UpdateFaq, GetOne, DeleteOne
}
export default FaqsService